import React, { useContext } from 'react';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

// fluxs
import CatalogContext from '../../fluxs/contexts/CatalogContext';
import {
  CHANGE_SEARCHING,
  CHANGE_CONDITION,
} from '../../fluxs/actions';

// other utilities
import {
  formatDecimal,
} from '../../utils';
import { categories } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  sidemenu: {
    height: 'auto',
    border: 'solid 1px #555859',
    borderRadius: theme.spacing(1),
    padding: theme.spacing(3, 3, 5, 3),
  },
  titleCategory: {
    margin: theme.spacing(2, 0),
  },
  itemParent: {
    borderBottom: 'dotted 1px #555859',
    display: 'block',
  },
  titlePrice: {
    margin: theme.spacing(4, 0, 2, 0),
  },
  priceSelect: {
    height: 45,
    width: '100%',
    border: 'solid 1px white',
    borderRadius: theme.spacing(5),
    background: 'url("data:image/svg+xml,<svg width=\'12\' height=\'12\' xmlns=\'http://www.w3.org/2000/svg\'><path fill=\'white\' d=\'m0,3l6,6l6,-6l-12,0z\'/><path fill=\'none\' d=\'m0,0l12,0l0,12l-12,0l0,-12z\'/></svg>") no-repeat 90% center',
    fontSize: 18,
    padding: theme.spacing(0, 2),
    color: 'white',
    outline: 'none',
    position: 'relative',
    marginTop: 10,
    '-webkit-appearance': 'none',
  },
  priceOptions: {
    backgroundColor: '#3b4043',
  },
  '@keyframes selecting': {
    from: {
      height: 45,
    },
    to: {
      height: 450,
    },
  },
}));

const prices = [0, 500, 400, 300, 200, 100];

const CatalogSideMenu = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(CatalogContext);

  // カテゴリの選択
  const handleClickCategory = (selectedCategory) => {
    console.log('【Click Event】CatalogSideMenu: handleClickCategory', selectedCategory);
    // 選択中のカテゴリの場合は何もしない
    if (selectedCategory === state.condition.category) return;

    // カテゴリ変更時はurlを変えて再検索
    dispatch({ type: CHANGE_SEARCHING, isSearching: true });
    dispatch({ type: CHANGE_CONDITION, category: selectedCategory });
  };

  // 金額の選択
  const handleChangePrice = (selectedPrice) => {
    console.log('【Click Event】CatalogSideMenu: handleChangePrice', selectedPrice);
    // カテゴリ変更時はurlを変えて再検索
    dispatch({ type: CHANGE_SEARCHING, isSearching: true });
    dispatch({ type: CHANGE_CONDITION, price: parseInt(selectedPrice, 10) });
  };

  return (
    <>
      <Box className={classes.sidemenu}>
        <Typography variant="h5" className={classes.titleCategory}>
          Category
        </Typography>
        {categories.map((loopCategory) => (
          <div key={loopCategory.id}>
            {/* eslint-disable jsx-a11y/no-static-element-interactions */}
            {/* eslint-disable jsx-a11y/click-events-have-key-events */}
            <div
              className={`help-anchor-link ${classes.itemParent}`}
              onClick={() => handleClickCategory(loopCategory.id)}
            >
              <div className="help-anchor-link-text">
                {loopCategory.name}
              </div>
            </div>
          </div>
        ))}
        <Typography variant="h5" className={classes.titlePrice}>
          Price
        </Typography>
        <select
          className={classes.priceSelect}
          onChange={(e) => handleChangePrice(e.target.value)}
          value={state.condition.price}
        >
          {prices.map((price) => (
            <option key={price} value={price} className={classes.priceOptions}>
              {price === 0 ? 'ALL' : `～${formatDecimal(price)} pt`}
            </option>
          ))}
        </select>
      </Box>
    </>
  );
};

export default CatalogSideMenu;
