import React, { useContext } from 'react';
import PropTypes from 'prop-types';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

// material-ui icons

// custom components
import ProductCard from './ProductCard';

// fluxs
import CatalogContext from '../../fluxs/contexts/CatalogContext';
import {
  CHANGE_SEARCHING,
  CHANGE_CONDITION,
} from '../../fluxs/actions';

// other utilities
import { categories } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  loadingPage: {
    height: 800,
    [theme.breakpoints.down('sm')]: {
      height: 500,
    },
  },
  loadingProductList: {
    height: 100,
  },
  loadingCircle: {
    color: '#fff',
  },
  item: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 13, 0),
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 0, 10, 0),
    },
  },
  itemList: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3, 0, 13, 5),
    },
  },
  itemListTitle: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 0, 8, 0),
      fontSize: 24,
      borderBottom: '1px solid #d3d4d4',
    },
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 0, 6, 0),
    },
  },
  itemMoreWrap: {
    textAlign: 'right',
    paddingTop: 20,
  },
  itemMore: {
    display: 'inline-block',
    borderBottom: '1px solid #d3d4d4',
    cursor: 'pointer',
  },
}));

const CatalogMainContents = () => {
  const { state, dispatch } = useContext(CatalogContext);
  const classes = useStyles();

  // ローディング
  const LoadingPage = () => (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.loadingPage}
    >
      <CircularProgress className={classes.loadingCircle} />
    </Grid>
  );
  const isPageLoading = state.dispModes.isSearching || state.categories.length === 0;
  if (isPageLoading) return <LoadingPage />;

  // 画面部品群
  const NoQuiz = () => (
    <div className={classes.item}>
      この一覧には検索条件に当てはまるクイズがありませんでした。<br />
      条件を変更して再度検索してください。
    </div>
  );

  const NoHistory = () => (
    <div className={classes.item}>
      閲覧履歴に保存されているクイズがありません。
    </div>
  );

  const handleClickCategory = (selectedCategory) => {
    console.log('【Click Event】CatalogSideMenu: handleClickCategory', selectedCategory);
    // 選択中のカテゴリの場合は何もしない
    if (selectedCategory === state.condition.category) return;

    // カテゴリ変更時はurlを変えて再検索
    dispatch({ type: CHANGE_SEARCHING, isSearching: true });
    dispatch({ type: CHANGE_CONDITION, category: selectedCategory });

    window.scrollTo(0, document.querySelector('.catalog-mv').offsetHeight);
  };

  // タイトル&クイズのかたまり
  const ProductList = ({ categoryId, categoryName, products }) => {
    // 検索中 or 0件
    let isNoCassette = false;
    let NoCassetteComponent = null;
    const {
      search,
      price,
      category,
    } = state.condition;
    const isTop = category === 'top';

    if (state.dispModes.isSearchingByCategory[categoryId]) {
      isNoCassette = true;
      NoCassetteComponent = <LoadingPage />;
    } else if (products.length === 0) {
      const isNoCondition = search === '' && price === 0;
      isNoCassette = true;
      NoCassetteComponent = categoryId === 'history' && isNoCondition ? <NoHistory /> : <NoQuiz />;
    }

    return (
      <Box className={classes.itemList}>
        <Typography variant="h4" className={classes.itemListTitle}>
          {categoryName}
        </Typography>
        {isNoCassette ? NoCassetteComponent : products.map((product) => (
          <Grid key={product.ProductID} container className={classes.item}>
            <ProductCard product={product} />
          </Grid>
        ))}
        {isTop &&
          <Box className={classes.itemMoreWrap}>
            <span className={classes.itemMore} onClick={() => handleClickCategory(categoryId)}>もっと見る</span>
          </Box>
        }
      </Box>
    );
  };

  ProductList.propTypes = {
    categoryId: PropTypes.string.isRequired,
    categoryName: PropTypes.string.isRequired,
    products: PropTypes.arrayOf(PropTypes.shape({
      ProductID: PropTypes.string.isRequired,
    })).isRequired,
  };

  // 表示対象の一覧を決定
  const { condition, products } = state;
  let displayProductLists = [];

  // TOP：ALL以外の全カテゴリを3件表示
  if (condition.category === 'top') {
    displayProductLists = categories.filter((c) => c.id !== 'top' && c.id !== 'allQuiz').map((category) => ({
      categoryId: category.id,
      categoryName: category.name,
      products: products[category.id].slice(0, 3),
    }));

  // Latest：10件のみ表示、それ以外は全件表示
  } else {
    const cnm = categories.filter((c) => c.id === condition.category)[0].name;
    const pdcs = products[condition.category];

    displayProductLists = [{
      categoryId: condition.category,
      categoryName: cnm,
      products: condition.category === 'latest' ? pdcs.slice(0, 10) : pdcs,
    }];
  }

  // 画面表示
  return (
    <>
      {displayProductLists.map((list) => (
        <ProductList
          key={list.categoryId}
          categoryId={list.categoryId}
          categoryName={list.categoryName}
          products={list.products}
        />
      ))}
    </>
  );
};

export default CatalogMainContents;
