import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles((theme) => ({
  footerMenu: {
    margin: 0,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  footerMenuItem: {
    display: 'inline-block',
    margin: theme.spacing(0, 2, 0, 2),
  },
  footerMenuLink: {
    textDecoration: 'none',
    fontSize: 14,
    color: '#fff',
    display: 'inline-block',
    lineHeight: 1.5,
    borderBottom: '1px solid #707070',

  },
}));

const Footer = () => {
  const classes = useStyles();
  return (
    <footer>
      <ul className={classes.footerMenu}>
        <li className={classes.footerMenuItem}>
          <a className={classes.footerMenuLink} href="/privacy">Privacy Policy</a>
        </li>
        <li className={classes.footerMenuItem}>
          <a className={classes.footerMenuLink} href="/terms">Term of services</a>
        </li>
      </ul>
        Copyright © {new Date().getFullYear()} NoteBuddy, Inc.
    </footer>
  );
};

export default Footer;
