import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Backdrop from '@material-ui/core/Backdrop';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';

// material-ui icons
import CloseIcon from '@material-ui/icons/Close';

// custom components
import Thumbnail from '../common/Thumbnail';
import AlexaImage from '../../assets/img/alexa.png';

// fluxs
import CatalogContext from '../../fluxs/contexts/CatalogContext';
import { CHANGE_SHOWING_PRODUCT_MODAL } from '../../fluxs/actions';

// other utilities
import {
  graphql,
  makeCategoryPageUrl,
  setProductsHistory,
} from '../../utils';
import { getProductByProductId, makeQueryGetSamples } from '../../graphql/queries';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
  },
  detailModalWrapper: {
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 4, 8, 4),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 4, 10, 4),
    },
  },
  closeButton: {
    width: '100%',
    maxWidth: 800,
    padding: 0,
    textAlign: 'right',
    color: 'white',
  },
  detailModal: {
    width: '100%',
    height: '95%',
    maxWidth: 800,
    maxHeight: 800,
    color: '#3b4043',
    backgroundColor: 'white',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      borderRadius: 4,
      padding: theme.spacing(4),
      backgroundSize: '50%',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: theme.spacing(2),
      padding: theme.spacing(5),
    },
    '&::-webkit-scrollbar': {
      width: '0px !important',
    },
  },
  productWrapper: {
    display: 'flex',
  },
  thumbnail: {
    [theme.breakpoints.down('sm')]: {
      flexGrow: 0,
      flexShrink: 1,
      flexBasis: 150,
    },
  },
  productInfo: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      flexGrow: 0,
      flexShrink: 1,
      flexBasis: 'auto',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(0, 4),
    },
  },
  productId: {
    marginBottom: theme.spacing(2),
    color: '#e44040',
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  productTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  productPrice: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  productContents: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
      marginTop: 5,
    },
  },
  spIntroduction: {
    marginTop: theme.spacing(2),
  },
  line: {
    borderBottom: 'dashed 1px #888',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(3),
    },
  },
  purchaseInfo: {
    padding: theme.spacing(4, 0, 0, 0),
  },
  purchaseTitle: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      fontSize: 28,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  purchaseContents: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  stepWrap: {
    position: 'relative',
    zIndex: 1,
  },
  alexaImageArea: {
    width: '100%',
    textAlign: 'right',
    position: 'relative',
    zIndex: 0,
    opacity: 0.3,
  },
  alexaImage: {
    [theme.breakpoints.down('sm')]: {
      width: '40%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
      marginTop: '-25%',
    },
  },
  questionHolder: {
    marginTop: 20,
  },
  questionQ: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  questionAns: {
    marginLeft: 30,
  },
  questionA: {
    marginBottom: 10,
    display: 'inline-block',
    minWidth: '20%',
    paddingRight: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      lineHeight: 1.5,
    },
  },
  catHolder: {
    [theme.breakpoints.up('md')]: {
      marginLeft: 250,
    },
  },
  categoryItem: {
    display: 'inline-block',
    fontSize: 12,
    background: '#ffc525',
    padding: '3px 10px',
    borderRadius: 4,
    marginRight: theme.spacing(1),
    lineHeight: 1.5,
    color: '#444',
    width: 'auto',
  },
  loadingDetail: {
    width: '100%',
    height: '100%',
  },
  loadingDetailCircle: {
    color: '#bbb',
  },
}));

const ProductDetailModal = ({ productId }) => {
  const { state, dispatch } = useContext(CatalogContext);
  const classes = useStyles();
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [isLoadingDetail, setIsLoadingDetail] = useState(true);

  const purchaseText = [
    '1．「Trixa」を「Alexa、トリクサちゃん開いて」で起動します',
    `2．「 ${productId.toUpperCase()} をレンタルしたい」をAlexaに話しかけてください`,
    '3．レンタルの承認を確認されます',
    '4．OKであれば「はい」と言ってください',
  ];

  const purchaseFreeText = [
    '1．「Trixa」を「Alexa、トリクサちゃん開いて」で起動します',
    `2．「 ${productId} を開始して」をAlexaに話しかけてください`,
    '3．クイズが開始されます',
  ];

  const { isShowingProductModal } = state.dispModes;

  const handleClickBackdrop = () => {
    console.log('【Click Event】ProductDetailModal: handleClickBackdrop');
    dispatch({
      type: CHANGE_SHOWING_PRODUCT_MODAL,
      isShowingProductModal: false,
    });
    const url = makeCategoryPageUrl(state.condition);
    history.push(url);
    setIsLoadingDetail(true);
  };

  const cleanTags = (str) => str.replace(/<\/?[^>]+(>|$)/g, '');

  useEffect(() => {
    let unmount = false;
    const getSampleQuestion = async (targetProductId, orgProduct) => {
      const retProduct = orgProduct;

      // サンプル設問取得クエリの作成
      const getSampleQuery = makeQueryGetSamples(1);
      const variable = {
        pid0: {
          ProductID: targetProductId,
        },
      };

      // サンプル設問の取得（クエリ実行）
      const ret = await graphql(getSampleQuery, variable);
      const q = ret.data.data.prd0.Questions;

      if (!retProduct) {
        return q;
      }
      retProduct.Questions = q;
      setProduct(retProduct);
      setProductsHistory(retProduct);
      setIsLoadingDetail(false);

      // トップへスクロール
      document.querySelector('.inner-scroll').parentElement.scrollTop = 0;
      return null;
    };

    const getProduct = async (targetProductId) => {
      const query = getProductByProductId;
      const options = {
        GetProductByProductIdInput: {
          ProductID: targetProductId,
        },
      };

      if (!unmount) {
        console.log('ProductDetailModal: call graphql GetProductByProductId', { query, options });
        const { data } = await graphql(query, options);
        const q = await getSampleQuestion(targetProductId);
        const prod = data.data.getProduct;
        prod.Questions = q;

        setProduct(prod);
        setProductsHistory(prod);
        setIsLoadingDetail(false);

        // トップへスクロール
        document.querySelector('.inner-scroll').parentElement.scrollTop = 0;
      }
    };

    // 初期処理
    if (productId && productId !== '') {
      const allProducts = Object.keys(state.products)
        .reduce((pre, category) => {
          if (category === 'history') return pre;
          return pre.concat(state.products[category]);
        }, []);
      const targetProduct = allProducts.filter((p) => (p.ProductID === productId))[0];

      if (targetProduct) {
        console.log('ProductDetailModal: display from %ccache.', 'color: blue', { productId, targetProduct });
        getSampleQuestion(productId, targetProduct);
      } else {
        console.log('ProductDetailModal: display from %cAPI.', 'color: red', { productId, targetProduct });
        getProduct(productId);
      }
    }

    const cleanup = () => {
      unmount = true;
    };
    return cleanup;
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    productId,
  ]);

  const getCategoryName = (catId) => {
    const cats = state.categories;
    if (cats) {
      const res = cats.find((item) => item.CategoryID === catId);
      return res.CategoryName;
    }
    return '';
  };

  const LoadingDetail = () => (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      className={classes.loadingDetail}
    >
      <CircularProgress className={classes.loadingDetailCircle} />
    </Grid>
  );

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={isShowingProductModal}
        onClick={handleClickBackdrop}
      >
        <Box className={classes.detailModalWrapper}>
          <Container className={classes.closeButton}>
            <CloseIcon />
          </Container>
          <Container
            className={classes.detailModal}
            onClick={(e) => e.stopPropagation()}
          >

            {isLoadingDetail || state.categories.length === 0 ? <LoadingDetail /> : (
              <>
                <div className="inner-scroll">
                  <div className={classes.productWrapper}>
                    <Box className={classes.thumbnail}>
                      <Thumbnail url={product.Thumbnail} fixwidth={state.dispModes.isSp} />
                    </Box>
                    <Box className={classes.productInfo}>
                      <Typography variant="h4" className={classes.productTitle}>
                        <span className={classes.productId}>
                          【{product.ProductID.toUpperCase()}】
                        </span>
                        {product.Title[0].title}
                      </Typography>
                      <Typography variant="subtitle1" className={classes.productPrice}>
                        {product.Price !== 0 ? `${product.Price}pt` : '無料'}
                      </Typography>
                      <Typography variant="subtitle1" className={classes.productContents}>製作者：{product.CreatorID}</Typography>
                      <Hidden smDown>
                        <Typography variant="subtitle1" className={classes.productContents}>{product.Introduction[0].introduction}</Typography>
                      </Hidden>
                    </Box>
                  </div>
                  <Hidden mdUp>
                    <div className={classes.spIntroduction}>
                      <Typography variant="subtitle1" className={classes.productContents}>{product.Introduction[0].introduction}</Typography>
                    </div>
                  </Hidden>

                  <Box className={classes.catHolder}>
                    {product.Category.map((cat) => (
                      <span key={cat} className={classes.categoryItem}>{getCategoryName(cat)}</span>
                    ))}
                  </Box>

                  {/* line */}
                  <Box className={classes.line} />

                  <Box className={classes.questionHolder}>
                    <Typography variant="h4" className={classes.purchaseTitle}>問題例</Typography>
                    {/* sample question */}
                    {product.Questions && product.Questions.map((q) => (
                      <Box key={q.Question}>
                        <Box className={classes.questionQ}>Q. {cleanTags(q.Question)}</Box>
                        <Box className={classes.questionAns}>
                          {q.Choices.map((c, ky) => (
                            <span className={classes.questionA} key={c}>
                              {String.fromCharCode(65 + ky)}. {cleanTags(c)}
                            </span>
                          ))}
                        </Box>
                      </Box>
                    ))}
                  </Box>

                  {/* line */}
                  <Box className={classes.line} />

                  {/* purchase info */}
                  <Grid container justify="space-between">
                    <Grid item sm={12}>
                      <Box className={classes.purchaseInfo}>
                        <Typography variant="h4" className={classes.purchaseTitle}>購入方法</Typography>
                        <Box className={classes.stepWrap}>
                          {product.Price > 0 && purchaseText.map((text) => (
                            <Typography key={text} variant="subtitle1" className={classes.purchaseContents}>{text}</Typography>
                          ))}

                          {product.Price === 0 && purchaseFreeText.map((text) => (
                            <Typography key={text} variant="subtitle1" className={classes.purchaseContents}>{text}</Typography>
                          ))}
                        </Box>
                        <Box className={classes.alexaImageArea}>
                          <img src={AlexaImage} className={classes.alexaImage} alt="alexa" />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              </>
            )}

          </Container>
        </Box>
      </Backdrop>

    </>
  );
};

ProductDetailModal.defaultProps = {
  productId: '',
};

ProductDetailModal.propTypes = {
  productId: PropTypes.string,
};

export default ProductDetailModal;
