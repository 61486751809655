import React, { useContext } from 'react';
import clsx from 'clsx';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';

// material-ui icons

// custom components

// fluxs
import CatalogContext from '../../fluxs/contexts/CatalogContext';
import { CHANGE_SEARCHING, CHANGE_CONDITION } from '../../fluxs/actions';

// other utilities
import { categories } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  sidemenu: {
  },
  gridList: {
    flexWrap: 'nowrap',
    margin: '0 !important',
    '&::-webkit-scrollbar': {
      height: '5px !important',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '10px !important',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '10px !important',
    },
  },
  item: {
    width: 'auto !important',
    height: 'auto !important',
  },
  link: {
    textTransform: 'none',
    color: '#909090',
    padding: theme.spacing(0, 1),
    margin: theme.spacing(1, 1),
    transition: 'all .1s ease-out',
    height: 31,
  },
  selected: {
    color: '#ffffff',
    fontSize: 18,
    borderBottom: '2px solid #ffffff',
    borderRadius: 0,
  },
}));


const CatalogScrollMenu = () => {
  const classes = useStyles();
  const { state, dispatch } = useContext(CatalogContext);

  const handleClickCategory = (selectedCategory) => {
    console.log('【Click Event】CatalogScrollMenu: handleClickCategory', selectedCategory);
    // 選択中のカテゴリの場合は何もしない
    if (selectedCategory === state.condition.category) return;

    // カテゴリ変更時はurlを変えて再検索
    console.log('CatalogScrollMenu: call dipatches');
    dispatch({ type: CHANGE_SEARCHING, isSearching: true });
    dispatch({ type: CHANGE_CONDITION, category: selectedCategory });
  };

  return (
    <>
      <Box className={classes.sidemenu}>
        <GridList className={classes.gridList}>
          {categories.map((category) => (
            <GridListTile key={category.name} className={classes.item}>
              <Button
                className={clsx(
                  classes.link,
                  state.condition.category === category.id && classes.selected,
                )}
                onClick={() => handleClickCategory(category.id)}
              >
                {category.name}
              </Button>
            </GridListTile>
          ))}
        </GridList>
      </Box>
    </>
  );
};

export default CatalogScrollMenu;
