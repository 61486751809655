import {
  UPDATE_PRODUCTS,
} from '../actions';

const products = (state = [], action) => {
  switch (action.type) {
    case UPDATE_PRODUCTS: {
      const { targetCategory, updatedProducts } = action;
      console.log('reducers: UPDATE_PRODUCTS', { targetCategory, updatedProducts });
      const upd = {
        [targetCategory]: updatedProducts,
      };
      return { ...state, ...upd };
    }
    default: {
      return state;
    }
  }
};

export default products;
