import React from 'react';

// material-ui components
import Header from '../Header';

const CatalogHeader = () => (
  <>
    {/* header */}
    <Header active="catalog" />

    {/* mainVisual */}
    <div className="catalog-mv">
      <div className="catalog-mv-inner">
        <span className="catalog-mv-title">
          <img src="/assets/img/svg/logo.svg" alt="Trixa" />
          <img src="/assets/img/svg/pagettl_catalog.svg" alt="Catalog" />
        </span>
        <p className="catalog-mv-text">たくさんのクイズを楽しめます</p>
      </div>
    </div>
  </>
);

export default CatalogHeader;
