import React from 'react';
import '../../assets/css/lp.css';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

// other utilities
import {
  graphql,
} from '../../utils';
import {
  searchProducts,
  getCategories,
} from '../../graphql/queries';

class Start extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      scrollTop: 0,
      what: '',
      spec: '',
      native: '',
      start: '',
      menu: false,
      fix: false,
      catalogDetail: [],
      categories: [],
    };
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    document.getElementsByClassName('trixa-body')[0].scrollTop = 0;
    this.getCatalogList();
  }

  async getLPCatalog() {
    return await new Promise((resolve, reject) => {
      fetch('/assets/json/lp-catalog.json', {})
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw Error(res.status);
          }
        })
        .then(
          (result) => {
            this.setState({
              catalogIds: result.items,
            });
            resolve(result.items);
          },
          (error) => {
            this.setState({
              catalogIds: [],
            });
            reject([]);
          },
        );
    });
  }

  async getCatalogList() {
    const prodId = await this.getLPCatalog();
    // const filter = (id) => {
    //   return {
    //     filter: {
    //       ProductID: {
    //         eq: id,
    //       },
    //       Search: {
    //         contains: id,
    //       },
    //     },
    //   };
    // };
    const dispCatalog = [];

    const query = searchProducts.replace(/___LIMIT___/, 30);
    const { data } = await graphql(query);

    let cat = await graphql(getCategories);
    if (cat && cat.data) {
      cat = cat.data.data.listCategories.items;
    }

    data.data.listProducts.items.map((item) => {
      if (prodId.indexOf(item.ProductID) !== -1) {
        dispCatalog.push(item);
      }
      return null;
    });

    this.setState({
      catalogDetail: dispCatalog,
      categories: cat,
    });
  }

  getCategoryName(catId) {
    const { categories } = this.state;
    const cat = categories.find((item) => item.CategoryID === catId);
    return cat.CategoryName;
  }

  onScroll() {
    const { scrollTop } = this.myRef.current;

    const ht = window.innerHeight;
    const aht = document.getElementsByClassName('trixa-what')[0].offsetHeight;
    const bht = document.getElementsByClassName('trixa-spec')[0].offsetHeight;
    const cht = document.getElementsByClassName('trixa-native')[0].offsetHeight;

    const sht = ht + aht * 0.3;
    const nht = ht + aht + bht * 0.6;
    const fht = ht + aht + bht + cht * 0.3;
    this.setState({
      scrollTop,
      what: this.state.what == '' ? (scrollTop > ht * .5 ? 'visible' : '') : this.state.what,
      spec: this.state.spec == '' ? (scrollTop > sht ? 'visible' : '') : this.state.spec,
      native: this.state.native == '' ? (scrollTop > nht ? 'visible' : '') : this.state.native,
      start: this.state.start == '' ? (scrollTop > fht ? 'visible' : '') : this.state.start,
      fix: scrollTop + 50 > ht,
    });
  }

  toggleMenu() {
    this.setState({
      menu: !this.state.menu,
    });
    return false;
  }

  render() {
    const {
      menu,
      fix,
      what,
      spec,
      catalogDetail,
      native,
      start,
    } = this.state;
    return (
      <div className={`trixa-body ${menu ? ' slided' : ''}`} ref={this.myRef} onScroll={this.onScroll}>
        <div className="trixa-loading">
          <div className="trixa-loading-img">
            <img src="assets/img/svg/loading.svg" alt="loading" />
            <img src="assets/img/svg/loading.svg" alt="loading" />
            <img src="assets/img/svg/loading.svg" alt="loading" />
            <span className="trixa-loading-text">loading...</span>
          </div>
        </div>
        <div className="trixa-main" id="top">
          <div className="trixa-main-top">
            <div className="trixa-inner">
              <div className="trixa-menu">
                <span className={`trixa-menu-control ${fix}`} onClick={(e) => this.toggleMenu(e)}>
                  <img src="assets/img/svg/sp_menu.svg" alt="menu"/>
                </span>
                <div className={` trixa-menu-contents ${menu ? ' visible' : ''}`}>
                  <span className="trixa-menu-title sp" href="#top">
                    <img src="assets/img/svg/pagettl_sp_menu.svg" alt="menu" />
                  </span>
                  <a className="trixa-menu-a sp" onClick={(e) => this.toggleMenu(e)} href="#top">Home</a>
                  <a className="trixa-menu-a" href="https://note-buddy.com/" target="_blank" rel="noopener noreferrer">Company</a>
                  <a className="trixa-menu-a" href="/catalog">Catalog</a>
                  <a className="trixa-menu-a" href="/help">Help</a>
                  <a className="trixa-menu-a sp" href="/privacy">Privacy Policy</a>
                  <a className="trixa-menu-a sp" href="/terms">Term of services</a>
                  <Icon
                    onClick={(e) => this.toggleMenu(e)}
                    className="close-icon"
                    path={mdiClose}
                    title="Close"
                    size={1}
                  />
                </div>
              </div>
              <div className="trixa-main-hello">
                <img src="assets/img/svg/fv_hello.svg" alt="Hello" />
              </div>
              <div className="trixa-main-text">
                <span className="trixa-main-text-01">
                  <img src="assets/img/svg/fv_ttl_welcome.svg" alt="Welcome" />
                </span>
                <p />
                <span className="trixa-main-text-02">
                  <img src="assets/img/svg/fv_ttl_to.svg" alt="To" />
                </span>
                <p />
                <span className="trixa-main-text-03">
                  <img src="assets/img/svg/fv_ttl_trixa.svg" alt="Trixa" />
                </span>
              </div>
            </div>
          </div>
          <span className="trixa-scroll">Scroll</span>
        </div>
        <div className={`trixa-what ${what}`} id="about">
          <div className="trixa-inner about">
            <div className="trixa-left-part">
              <img className="trixa-what-img" width="440" src="/assets/img/svg/about_img.svg" alt="About" />
            </div>
            <div className="trixa-right-part">
              <h2 className="trixa-subtitle">
                <span className="trixa-subtitle-inner">
                  <img src="assets/img/svg/whats_trixa.svg" alt="What' s Trixa?" />
                </span>
              </h2>
              <p className="trixa-desc">
                Trixa(トリクサ)は、いろいろなクイズを出題し、楽しく学べる<a href="https://ja.wikipedia.org/wiki/Amazon_Alexa" target="_blank" rel="noopener noreferrer">Alexa</a>スキルです。<br />
                子供から大人、学生から社会人まで、語学・世界知識・数学等、様々なジャンルのクイズを選択できます。<br />
                Alexaの使い道が分からない方、Alexaとの時間を過ごしたい方、Alexaで新しいことを学びたい方、Alexaで自分の実力を試したい方、<br />
                Alexaでお子様と一緒に勉強したい方、Trixaがお答えします。
              </p>
              <p className="trixa-btn-wrap">
                <a className="trixa-btn" href="/help/">Trixaの使い方を詳しく見る</a>
              </p>
            </div>
          </div>
        </div>
        <div className={`trixa-spec ${spec}`} id="catalog">
          <div className="trixa-inner spec">
            <h2 className="trixa-subtitle spec">
              <span className="trixa-subtitle-inner"><span>いろんな試験を選ぶことができます</span></span>
            </h2>
            <p className="trixa-desc">
              Trixaでは、下記のような、無料クイズから有料まで、たくさんのクイズを楽めます。<br />
              <a href="/catalog">Trixa Catalog</a> よりそれぞれのクイズの詳細を確認することができます。
            </p>
            {catalogDetail.length > 0 && (
              <ul className="trixa-catalog">
                {catalogDetail.map((item) => (
                  <li className="trixa-quiz" key={item.ProductID}>
                    <dl className="trixa-item">
                      <dt className="trixa-item-img">
                        <img className="trixa-item-thumbs" src={item.Thumbnail} alt="img" />
                        <span className="trixa-item-new">NEW</span>
                      </dt>
                      <dd className="trixa-item-detail">
                        <p className="trixa-item-title"><span className="trixa-item-title-id">【{item.ProductID.toUpperCase()}】</span>{item.Title[0].title}</p>
                        <p className="trixa-item-creator">作者：{item.CreatorID}</p>
                        <p className="trixa-item-price">{item.Price ? `${item.Price} pt` : '無料' }</p>
                        <p className="trixa-item-desc">{item.Introduction[0].introduction.length > 50 ? `${item.Introduction[0].introduction.substr(0, 50)}...` : item.Introduction[0].introduction}</p>
                        {item.Category && (
                          <ul className="trixa-cat-list">
                            {item.Category.map((cat) => (
                              <li className="trixa-cat" key={cat}>{this.getCategoryName(cat)}</li>
                            ))}
                          </ul>
                        )}
                        <a
                          className="trixa-item-btn"
                          target="_blank"
                          rel="noopener noreferrer"
                          href={`/catalog/${item.ProductID}`}
                        >
                          <img className="trixa-btn-icon" src="assets/img/svg/icon_ticket.svg" alt="購入" />購入方法
                        </a>
                      </dd>
                    </dl>
                  </li>
                ))}
              </ul>
            )}
            <p className="trixa-btn-wrap">
              <a className="trixa-btn" href="/catalog">クイズカタログを見る</a>
            </p>
          </div>
        </div>
        <div className={`trixa-native ${native}`}>
          <div className="trixa-inner native">
            <h2 className="trixa-subtitle native">
              <span className="trixa-subtitle-inner">
                <span>ネイティブのようなやり取りが可能</span>
              </span>
            </h2>
            <div className="trixa-right-native">
              <p className="trixa-desc">
                Trixaは英語・フランス語など、語学のネイティブのような発音が聞けます。<br />
                ネイティブスピーカーと１対１のような感覚で、緊張感無く気軽にネイティブ発音をリスニングすることができます。<br />
                外国の方に直接合わなくても、Alexaがいれば、どんな場所でも気軽に体験することができます。<br />
                語学をもっと学びたい方、ぜひお試しください。
              </p>
            </div>
            <p className="trixa-btn-wrap">
              <a className="trixa-btn" href="/catalog/?search=語学">語学クイズカタログを見る</a>
            </p>
          </div>
        </div>
        <div className={` trixa-start ${start}`}>
          <div className="trixa-inner start">
            <h2 className="trixa-subtitle start">
              <span className="trixa-subtitle-inner">
                <span>さぁ、始めよう！</span>
              </span>
            </h2>
            <p className="trixa-desc">早速有効にして見ましょう！<br />※Trixaの有効化は、無料で行えます。</p>
            <p className="trixa-url-holder">
              <a className="trixa-skill-url" href="#">
                <img className="trixa-url-icon" src="assets/img/svg/trixa_logo_blue.svg" alt="リンク" />
              </a>
            </p>
            <p className="trixa-btn-wrap">
              <a className="trixa-btn" href="#">Amazonで見る</a>
            </p>
            <p className="trixa-social">
              <a className="trixa-social-icon" href="#">
                <img className="trixa-btn-icon" src="assets/img/svg/icon_facebook.svg" alt="facebook" />
              </a>
              <a className="trixa-social-icon" href="#">
                <img className="trixa-btn-icon" src="assets/img/svg/icon_twitter.svg" alt="twitter" />
              </a>
            </p>
            <p className="trixa-bottom-link">
              <a className="trixa-bottom-a" href="/privacy">Privacy Policy</a>
              <a className="trixa-bottom-a" href="/terms">Term of services</a>
            </p>
            <p className="trixa-copy">Copyright©2020 NoteBuddy,Inc.</p>
          </div>
        </div>
      </div>
    );
  }
}
export default Start;
