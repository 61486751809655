import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
  newBadge: {
    backgroundColor: '#ffc500',
    position: 'absolute',
    color: 'black',
    borderRadius: 20,
    fontSize: 10,
    textAlign: 'center',
    boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.2)',
    zIndex: 10,
    [theme.breakpoints.down('sm')]: {
      width: 28,
      height: 28,
      lineHeight: '28px',
    },
    [theme.breakpoints.up('md')]: {
      width: 40,
      height: 40,
      lineHeight: '40px',
    },
  },
  'position-leftTop': {
    [theme.breakpoints.down('sm')]: {
      top: -10,
      left: -10,
    },
    [theme.breakpoints.up('md')]: {
      top: -20,
      left: -20,
    },
  },
  'position-rightTop': {
    [theme.breakpoints.down('sm')]: {
      top: 8,
      right: -15,
    },
  },
}));

const NewBadge = ({ badgePosition }) => {
  const classes = useStyles();
  const newBadgeClass = clsx(
    classes.newBadge,
    badgePosition && classes[`position-${badgePosition}`],
  );

  return (
    <Icon className={newBadgeClass}>NEW</Icon>
  );
};

NewBadge.propTypes = {
  badgePosition: PropTypes.string.isRequired,
};

export default NewBadge;
