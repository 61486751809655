import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';

// custom components
import Thumbnail from '../common/Thumbnail';

// fluxs
import CatalogContext from '../../fluxs/contexts/CatalogContext';
import { CHANGE_SHOWING_PRODUCT_MODAL } from '../../fluxs/actions';

// other utilitiess
import {
  cutStr,
} from '../../utils';

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  item: {
    position: 'relative',
  },
  coverWrapper: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      top: -20,
      left: 0,
    },
  },
  cardWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardSpacer: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 0, 0, 22),
      width: '100%',
    },
  },
  cardContent: {
    backgroundColor: '#1f2b30',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(2),
      padding: theme.spacing(0),
      borderRadius: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 'auto',
      margin: theme.spacing(3, 0, 0, 43),
      padding: theme.spacing(2, 3, 3, 6),
      borderRadius: theme.spacing(2),
    },
  },
  cardHeader: {
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.down('md')]: {
      minHeight: 100,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 'auto',
    },
  },
  cardTitle: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  cardId: {
    color: '#ffc525',
    display: 'inline',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 20,
    },
  },
  cardCreator: {
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0, 2, 0),
      fontSize: 12,
    },
  },
  cardPrice: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
      textAlign: 'right',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 24,
      textAlign: 'right',
      marginLeft: theme.spacing(2),
    },
  },
  cardDetail: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
      marginBottom: 5,
      lineHeight: 2,
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: 14,
      marginBottom: 5,
    },
  },
  purchaseButton: {
    position: 'absolute',
    color: 'white',
    fontSize: 18,
    background: 'url(/assets/img/svg/icon_ticket.svg) no-repeat 10px center #e44040',
    boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.2)',
    zIndex: 10,
    paddingLeft: 40,
    '&:hover': {
      backgroundColor: '#e35454',
    },
    [theme.breakpoints.down('sm')]: {
      bottom: -20,
      right: 20,
      fontSize: 16,
    },
    [theme.breakpoints.up('md')]: {
      bottom: -10,
      right: 30,
    },
  },
  purchaseButtonIcon: {
    color: 'white',
    margin: theme.spacing(0, 2, 0, 0),
    width: 20,
  },
  loadingArea: {
    height: '100%',
  },
  loadingCircle: {
    color: 'gray',
  },
  cardInner: {
    padding: theme.spacing(3, 3, 4, 3),
    [theme.breakpoints.up('md')]: {
      paddingBottom: `${theme.spacing(5)}px !important`,
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 4, 4, 4),
      paddingBottom: `${theme.spacing(7)}px !important`,
    },
  },
  category: {
    display: 'inline-block',
    fontSize: 12,
    background: '#ffc525',
    padding: '3px 10px',
    borderRadius: 4,
    marginRight: theme.spacing(1),
    lineHeight: 1.5,
    color: '#444',
    width: 'auto',
  },
}));

const ProductCard = ({ product }) => {
  const { state, dispatch } = useContext(CatalogContext);
  const classes = useStyles();
  const history = useHistory();

  const productId = product.ProductID;
  const productIdTxt = `【${productId.toUpperCase()}】`;
  const url = `/catalog/${productId}`;
  const productTitle = product.Title[0].title; // cutStr(product.Title[0].title, 13);
  const creator = cutStr(product.CreatorID, 10);
  const price = product.Price;
  const introduction = cutStr(product.Introduction[0].introduction, 65);
  const categories = product.Category;

  const handleClickCard = (e) => {
    console.log('【Click Event】ProductCard: handleClickCard', { productId });
    e.preventDefault();
    dispatch({ type: CHANGE_SHOWING_PRODUCT_MODAL, isShowingProductModal: true });
    history.push(url);
  };

  const getCategoryName = (catId) => {
    const cats = state.categories;
    const res = cats.find((item) => item.CategoryID === catId);
    return res.CategoryName;
  };

  return (
    <>
      <a href={url} onClick={handleClickCard} className={classes.link}>
        <Grid container className={classes.item}>
          <Grid item className={classes.coverWrapper}>
            <Thumbnail url={product.Thumbnail} badgePosition={state.dispModes.isSp ? 'rightTop' : undefined} />
          </Grid>

          <Grid item style={{ position: 'relative' }} className={classes.cardWrapper}>
            <Card className={classes.cardContent} variant="outlined">
              <CardContent className={classes.cardInner}>
                <Grid
                  container
                  justify="flex-start"
                  alignItems="center"
                  className={classes.cardHeader}
                >
                  <Grid item sm={12} md={9} className={classes.cardSpacer}>
                    <Typography className={classes.cardTitle}>
                      <InputLabel className={classes.cardId}>
                        {productIdTxt}
                      </InputLabel>
                      {productTitle}
                    </Typography>
                    <Typography className={classes.cardCreator}>
                      製作者：{creator}
                    </Typography>
                  </Grid>
                  <Grid item sm={12} md={3} className={classes.cardSpacer}>
                    <Typography className={classes.cardPrice}>
                      {price ? `${price}pt` : '無料'}
                    </Typography>
                  </Grid>
                </Grid>
                <Typography className={classes.cardDetail}>
                  {introduction}
                </Typography>
                {categories.map((cat) => (
                  <Grid key={cat} container className={classes.category}>
                    {getCategoryName(cat)}
                  </Grid>
                ))}
              </CardContent>
            </Card>
            <Button variant="contained" className={classes.purchaseButton}>
              購入方法
            </Button>
          </Grid>
        </Grid>
      </a>
    </>
  );
};

ProductCard.propTypes = {
  product: PropTypes.shape({
    ProductID: PropTypes.string.isRequired,
    Category: PropTypes.arrayOf(PropTypes.string).isRequired,
    Title: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    CreatorID: PropTypes.string.isRequired,
    Price: PropTypes.number.isRequired,
    Introduction: PropTypes.arrayOf(
      PropTypes.shape({
        introduction: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    Thumbnail: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProductCard;
