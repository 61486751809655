import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/index.scss';
import App from './components/App';
// import * as serviceWorker from './utils/serviceWorker';

function noop() {}

if (process.env.NODE_ENV !== 'development' || process.env.REACT_APP_NO_CONSOLE) {
  console.log = noop;
  console.warn = noop;
  console.error = noop;
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

// serviceWorker.unregister();
