import React from 'react';
import Header from './Header';
import Footer from './Footer';

const Error = () => {
  return (
    <>
      <Header />
      <div className="error-inner">
        <h1 className="error-title">
          <span className="error-title-inner">404</span>
        </h1>
        <p className="error-text">ページ見つかりませんでした。</p>
        <p className="error-credit">Icon made from <a href="http://www.onlinewebfonts.com/icon">Icon Fonts</a> is licensed by CC BY 3.0</p>
      </div>
      <Footer />
    </>
  );
};

export default Error;
