import axios from 'axios';
import dayjs from 'dayjs';
import querystring from 'querystring';

import { trixaBreakpoints } from '../assets/styles/theme';
import { historyStorageName } from './constants';

export const randomInt = (max) => Math.floor(Math.random() * max);
export const formatter = new Intl.NumberFormat('us-EN', {
  style: 'currency',
  currency: 'USD',
});
export const formatYen = (number) => new Intl.NumberFormat('ja-JP', {
  style: 'currency',
  currency: 'JPY',
}).format(number);
export const formatDecimal = (number) => `${new Intl.NumberFormat('ja-JP', {
  style: 'decimal',
}).format(number)}`;
export const getDate = (dateStr = undefined) => dayjs(dateStr).format('DD MMM, YYYY');
export const createDate = (value, unit = 'day', dateStr = undefined) => dayjs(dateStr).add(value, unit).format('DD MMM, YYYY');
export const formatProductData = ({
  Title, Introduction, LastUpdate, CreatedTime,
}) => {
  const jpnTitle = Title ? Title[0].title : '';
  const jpnTitleKana = Title ? Title[0].titleKana : '';
  const jpnIntroduction = Introduction ? Introduction[0].introduction : '';
  const lastUpdate = LastUpdate ? getDate(LastUpdate * 1000) : '';
  const createdTime = CreatedTime ? getDate(CreatedTime * 1000) : '';
  return {
    jpnTitle, jpnTitleKana, jpnIntroduction, lastUpdate, createdTime,
  };
};
export const cutStr = (str, cnt) => (cnt < str.length ? `${str.slice(0, cnt - 1)}…` : str);
export const graphql = async (query, variables) => {
  const endpoint = 'https://4hmscy7k37.execute-api.ap-northeast-1.amazonaws.com/dev/graphql';
  const graphqlResponse = await
  axios.post(
    endpoint, {
      query,
      variables: JSON.stringify(variables),
    },
  ).then((response) => {
    console.log('【graphql】: request success.', response.data.data, response);
    return response;
  })
    .catch((error) => {
      console.log('【graphql】: request failed.', error);
    });
  return graphqlResponse;
};
export const getParamObj = (location) => querystring.parse(location.search.replace(/^\?/, ''));
export const getMediaQuery = () => {
  let mediaQuery = 'xs';
  const { keys, values } = trixaBreakpoints;
  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const val = values[key];
    if (window.matchMedia(`(max-width: ${val - 1}px)`).matches) {
      mediaQuery = key;
      break;
    }
  }
  return mediaQuery;
};
export const isSp = getMediaQuery() === 'xs' || getMediaQuery() === 'sm' || getMediaQuery() === 'md';
export const makeCategoryPageUrl = (conditions) => {
  const param = Object.keys(conditions).sort().map((cond) => {
    const val = conditions[cond];
    const condStr = `${cond}=${conditions[cond]}`;
    if (val) {
      switch (cond) {
        case 'search': return val !== '' ? condStr : null;
        case 'category': return val !== 'top' ? condStr : null;
        case 'price': return val !== '0' ? condStr : null;
        case 'sort': return val !== '' ? condStr : null;
        default: return null;
      }
    }
    return null;
  })
    .filter((x) => !!x)
    .join('&');
  const url = `/catalog${(param ? `?${param}` : '')}`;
  return url;
};

export const getProductsHistory = () => {
  const histStr = localStorage.getItem(historyStorageName);
  const histArr = histStr ? JSON.parse(histStr) : [];
  return Array.isArray(histArr) ? histArr : [];
};

export const setProductsHistory = (product) => {
  if (!product) return;
  console.log('utils: setProductsHistory', { product });
  const curHist = getProductsHistory();
  const history = [product].concat(
    curHist.filter((h) => h.ProductID !== product.ProductID),
  );
  localStorage.setItem(historyStorageName, JSON.stringify(history));
};

export const refineProducts = ({ products, condition: { search, price } }) => {
  const searchPrice = parseInt(price, 10);
  const refinedProducts = products.filter((product) => {
    const {
      Price,
      ProductID,
      Title,
      Introduction,
    } = product;

    if (searchPrice !== 0 && searchPrice < Price) return false; // 検索価格が設定されていて、商品価格の方が検索価格より高い

    const titleStr = Title.map((t) => t.title).join('　');
    const introductionStr = Introduction.map((i) => i.introduction).join('　');
    const searchTargetStr = `${ProductID} ${titleStr} ${introductionStr}`;
    const isIncluded = search.trim().replace(/(\s|\u{3000})+/g, ' ').split(' ').every((one) => searchTargetStr.indexOf(one) !== -1);
    if (!isIncluded) return false; // 商品にフリーワードが含まれない

    return true; // すべてを満たした場合はOK
  });
  return refinedProducts;
};
