import React from 'react';
import Icon from '@mdi/react';
import { mdiMicrophone } from '@mdi/js';

class Contents extends React.Component {
  constructor(props) {
	  super(props);
  }

  onScroll() {
	  if (!this.state.pos) {
		  let parts = document.getElementsByClassName('help-parts');
		  let pos = [];
		  for (let i = 0; i < parts.length; i++) {
			  pos.push({
				  id: parts[i].id,
				  pos: parts[i].offsetTop,
			  });
		  }
		  this.setState({
			  pos: pos,
		  });
	  }
	  const scrollY = window.scrollY - document.getElementsByClassName('command-guide')[0].offsetHeight + 20;
	  let posY = this.state.pos;
	  let target = null;
	  posY.forEach((pos) => {
		 if (pos.pos < scrollY) {
			 target = pos;
		 }
	  });
  }

  render() {
    let state = [];
    let exp = '';
    if (this.props.state && this.props.state.help) {
      state = this.props.state.help.datasources.guide;
		  exp = this.props.state.help.datasources.detail;
    }
    return (
      <div className="help-contents">
		    {exp &&
		      <p className="command-guide">{exp}</p>
		    }
        {state && state.map((item) => (
          <div className="help-parts" id={item.anchor} key={item.anchor}>
            <p className="command-title">
              <span className="command-title-inner">{item.title}</span>
            </p>
            <div className="command-bubble">
              <span className="command-bubble-icon-wrap">
				        <Icon
                  className="command-bubble-icon"
                  path={mdiMicrophone}
                  title="Speech"
                  size={1.2}/>
              </span>
              <div className="command-bubble-text">
                {item.commands.map((command, key) => (
                  <span key={key} className="command-bubble-text-component">{command}</span>
                ))}
              </div>
            </div>

            <p className="command-exp" dangerouslySetInnerHTML={{ __html: item.description.replace(/\n/gi, '<br/>').replace(/Trixaカタログ/gi, '<a href="/catalog/">Trixaカタログ</a>') }} />
          </div>
        ))}
      </div>
    );
  }
};

export default Contents;
