import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';

const Header = ({ active }) => {
  const [menuOpen, setMenuOpen] = useState(false);


  return (
    <header className={menuOpen ? 'open' : ''}>
      <div className="header-inner">
        <h1 className="help-title">
          <a href="/">
            <img className="header-logo" src="/assets/img/svg/logo.svg" alt="Trixa" />
          </a>
        </h1>
        <span className="help-glonav-control" onClick={() => setMenuOpen(!menuOpen)}>
          <img src="/assets/img/svg/sp_menu.svg" alt="menu" />
        </span>
        <ul className={`help-glonav${menuOpen ? ' open' : ''}`}>
          <li className="help-glonav-menu sp">
            <span className="help-glonav-title"><img src="/assets/img/svg/pagettl_sp_menu.svg" alt="menu" /></span>
          </li>
          <li className="help-glonav-menu">
            <a className="help-glonav-link" href="/">
              <span className="help-glonav-text">Home</span>
            </a>
          </li>
          <li className="help-glonav-menu">
            <a className="help-glonav-link" href="https://note-buddy.com/" target="_blank" rel="noopener noreferrer">
              <span className="help-glonav-text">Company</span>
            </a>
          </li>
          <li className="help-glonav-menu">
            {active === 'catalog' && (
              <span className="help-glonav-inactive">Catalog</span>
            )}
            {active !== 'catalog' && (
              <a className="help-glonav-link" href="/catalog">
                <span className="help-glonav-text">Catalog</span>
              </a>
            )}
          </li>
          <li className="help-glonav-menu">
            {active === 'help' && (
              <span className="help-glonav-inactive">Help</span>
            )}
            {active !== 'help' && (
              <a className="help-glonav-link" href="/help">
                <span className="help-glonav-text">Help</span>
              </a>
            )}
          </li>
          <li className="help-glonav-menu sp">
            {active === 'privacy' && (
              <span className="help-glonav-inactive">Privacy Policy</span>
            )}
            {active !== 'privacy' && (
              <a className="help-glonav-link" href="/privacy">
                <span className="help-glonav-text">Privacy Policy</span>
              </a>
            )}
          </li>
          <li className="help-glonav-menu sp">
            {active === 'terms' && (
              <span className="help-glonav-inactive">Term of services</span>
            )}
            {active !== 'terms' && (
              <a className="help-glonav-link" href="/terms">
                <span className="help-glonav-text">Term of services</span>
              </a>
            )}
          </li>
          <li className="help-glonav-close">
            <Icon
              onClick={() => setMenuOpen(!menuOpen)}
              className="help-glonav-close-icon"
              path={mdiClose}
              title="Close"
              size={1}
            />
          </li>
        </ul>
      </div>
    </header>
  );
};

Header.propTypes = {
  active: PropTypes.string.isRequired,
};

export default Header;
