import {
  CHANGE_SEARCHING,
  CHANGE_SHOWING_PRODUCT_MODAL,
  CHANGE_SEARCHING_BY_CATEGORY,
} from '../actions';

const dispModes = (state = {}, action) => {
  switch (action.type) {
    case CHANGE_SEARCHING: {
      console.log('reducers: CHANGE_SEARCHING', { action });
      const { isSearching } = action;
      return { ...state, isSearching };
    }
    case CHANGE_SHOWING_PRODUCT_MODAL: {
      console.log('reducers: CHANGE_SHOWING_PRODUCT_MODAL', { action });
      const { isShowingProductModal } = action;
      return { ...state, isShowingProductModal };
    }
    case CHANGE_SEARCHING_BY_CATEGORY: {
      console.log('reducers: CHANGE_SEARCHING_BY_CATEGORY', { action });
      const { isSearching, targetCategory } = action;
      return {
        ...state,
        isSearchingByCategory: {
          ...state.isSearchingByCategory,
          ...{ [targetCategory]: isSearching },
        },
      };
    }
    default: {
      return state;
    }
  }
};

export default dispModes;
