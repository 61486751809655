import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';

// material-ui icons
import SearchIcon from '@material-ui/icons/Search';

// custom components

// fluxs
import CatalogContext from '../../fluxs/contexts/CatalogContext';
import {
  UPDATE_PRODUCTS,
  CHANGE_SEARCHING,
  CHANGE_CONDITION,
  UPDATE_CATEGORIES,
  CHANGE_SEARCHING_BY_CATEGORY,
} from '../../fluxs/actions';

// other utilities
import {
  graphql,
  makeCategoryPageUrl,
  getProductsHistory,
  refineProducts,
} from '../../utils';
import { searchProducts, getCategories } from '../../graphql/queries';

const useStyles = makeStyles((theme) => ({
  searchArea: {
    padding: theme.spacing(6, 0, 8, 0),
  },
  searchInputBox: {
    width: '100%',
    border: 'solid 1px white',
    borderRadius: theme.spacing(8),
    background: 'none',
    padding: theme.spacing(3, 5),
    color: '#fff',
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      height: 50,
      fontSize: 18,
    },
    [theme.breakpoints.up('md')]: {
      height: 70,
      fontSize: 24,
    },
  },
  searchButtonArea: {
    paddingLeft: theme.spacing(6),
  },
  searchButton: {
    height: 70,
    width: '100%',
    border: 'solid 1px white',
    borderRadius: theme.spacing(8),
    fontSize: 24,
    padding: theme.spacing(3, 5),
    color: 'white',
    lineHeight: '32px',
  },
}));

const CatalogSearchArea = () => {
  const { state, dispatch } = useContext(CatalogContext);
  const classes = useStyles();
  const history = useHistory();

  const {
    search,
    category,
    price,
  } = state.condition;
  const [searchWord, setSearchWord] = useState(search);

  // 初期処理
  useEffect(() => {
    // 一覧更新前処理
    const preUpdate = () => {
      dispatch({ type: CHANGE_SEARCHING, isSearching: true });
    };

    // 一覧更新処理
    const excUpdate = ({ targetCategory, products }) => {
      dispatch({
        type: UPDATE_PRODUCTS,
        targetCategory,
        updatedProducts: products,
      });
      dispatch({ type: CHANGE_SEARCHING, isSearching: false });
      dispatch({ type: CHANGE_SEARCHING_BY_CATEGORY, isSearching: false, targetCategory });

      // 詳細モーダル表示でない場合はURL変更
      if (!state.dispModes.isShowingProductModal) {
        history.push(makeCategoryPageUrl(state.condition));
      }
    };

    // 検索＆表示処理
    const searching = async (targetCategory) => {
      preUpdate();

      const contains = search ? search.trim().replace(/(\s|\u{3000})+/g, ' ') : null;
      const query = searchProducts.replace(/___LIMIT___/, 50);
      const isExistsFilter = !!contains || !!price
        || targetCategory === 'editorsChoice'
        || targetCategory === 'popularContents';

      let options = {};
      if (isExistsFilter) {
        options = {
          filter: {},
        };
        // フリーワード
        if (contains) {
          options.filter.Search = { contains };
        }
        // ポイント
        if (price && price !== 0) {
          options.filter.Price = { lt: price };
        }
        // カテゴリ
        switch (targetCategory) {
          case 'editorsChoice':
          case 'popularContents':
            options.filter.Category = { eq: targetCategory };
            break;
          default:
        }
      }

      console.log('CatalogSearchArea: serching', { query, options });
      const { data } = await graphql(query, options);

      excUpdate({
        targetCategory,
        products: data.data.listProducts.items,
      });
    };

    // 閲覧履歴表示処理
    const restoreHistory = () => {
      preUpdate();
      // localStorageからProductsを取得
      const products = getProductsHistory();
      // Productsを絞り込み
      const refinedProducts = refineProducts({ products, condition: { price, search } });
      excUpdate({
        targetCategory: 'history',
        products: refinedProducts,
      });
    };

    // メイン処理
    if (category === 'top') {
      searching('latest');
      searching('editorsChoice');
      searching('popularContents');
      restoreHistory();
    } else if (category === 'history') {
      restoreHistory();
    } else {
      searching(category);
    }
  }, [ // eslint-disable-line react-hooks/exhaustive-deps
    category,
    price,
    search,
  ]);

  // 初回のみ
  useEffect(() => {
    // カテゴリーゲット
    const getAllCategories = async () => {
      const { data } = await graphql(getCategories);
      dispatch({
        type: UPDATE_CATEGORIES,
        updatedCategories: data.data.listCategories.items,
      });
    };
    getAllCategories();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // 検索ボタン押下
  const handleClickSearchButton = () => {
    dispatch({ type: CHANGE_CONDITION, search: searchWord });
  };

  // Enterサブミット
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      dispatch({ type: CHANGE_CONDITION, search: searchWord });
    }
  };
  return (
    <>
      <Grid
        container
        className={classes.searchArea}
      >
        <Grid item xs={12} md={9}>
          <Input
            type="text"
            placeholder="検索ワードを入力"
            className={classes.searchInputBox}
            value={searchWord}
            onChange={(e) => setSearchWord(e.target.value)}
            onKeyPress={handleKeyPress}
            disableUnderline
            endAdornment={(
              <Hidden mdUp>
                <InputAdornment position="end" onClick={handleClickSearchButton}>
                  <SearchIcon style={{ color: 'white' }} />
                </InputAdornment>
              </Hidden>
            )}
          />
        </Grid>
        {/* PC版のみ表示 */}
        <Hidden smDown>
          <Grid item md={3} className={classes.searchButtonArea}>
            <Button
              variant="outlined"
              className={classes.searchButton}
              onClick={handleClickSearchButton}
            >
              <SearchIcon style={{ color: 'white' }} />
              検索する
            </Button>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default CatalogSearchArea;
