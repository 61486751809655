import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';

const Terms = () => {
  const [contents, setContents] = useState(null);
  const getContents = () => {
    if (!contents) {
      fetch('/assets/json/terms/20200625.json', {})
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw Error(res.status);
          }
        })
        .then(
          (result) => {
            setContents(result);
          },
          (error) => {
            setContents(null);
          },
        );
    }
  };

  getContents();

  return (
    <>
      <Header active="terms" />
      {contents &&
        <div className="text-contents">
          <h1 className="text-contents-title">
            <span className="text-contents-title-inner">{contents.title}</span>
          </h1>
          <ol className="list-contents">
            {contents.contents.map((point, ind) => (
              <li className="list-contents-item" key={ind}>
                <dl className="text-part">
                  <dt className="text-heading"><span>{ind + 1}</span>{point.title}</dt>
                  <dd className="text-desc" dangerouslySetInnerHTML={{ __html: point.desc }}/>
                </dl>
              </li>
            ))}
          </ol>
        </div>
      }
      <Footer />
    </>
  );
};

export default Terms;
