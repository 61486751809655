import { createMuiTheme } from '@material-ui/core/styles';

export const AppName = 'Alexa001';
export const mockImage = 'https://source.unsplash.com/random';
export const styles = {
  fontSize: 16,
  fontSizeBig: 18,
  backgroundColorMain: '#282c34',
  colorMain: '#ffffff',
  marginDefault: 8,
  paddingDefault: 8,
  paddingVDefault: '8px 0',
};

export const trixaBreakpoints = {
  keys: ['xs', 'sm', 'md', 'lg', 'xl'],
  values: {
    xs: 0,
    sm: 640,
    md: 800,
    lg: 1160,
    xl: 1920,
  },
};

export const trixaTheme = createMuiTheme({
  spacing: 5,
  // Thie theme is created by https://in-your-saas.github.io/material-ui-theme-editor/.
  breakpoints: trixaBreakpoints,
});
