import { combineReducers } from 'redux';

import products from './products';
import dispModes from './dispModes';
import condition from './condition';
import categories from './categories';

export default combineReducers({
  products,
  dispModes,
  condition,
  categories,
});
