export const appName = 'Trixa';
export const categories = [
  { name: 'Top', id: 'top' },
  { name: 'All Quiz', id: 'allQuiz' },
  { name: 'Latest', id: 'latest' },
  { name: 'Editors Choice', id: 'editorsChoice' },
  { name: 'Popular Contents', id: 'popularContents' },
  { name: 'History', id: 'history' },
];
export const defaultCategory = categories.filter((c) => c.id === 'top')[0];
export const historyStorageName = 'trixa-products-history';
export const categoyRealIds = categories.filter((c) => c.id !== 'top').map((c) => c.id);
