// Reactのコアパッケージ
import React, { useEffect, useReducer } from 'react';
import { // SPA用
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
// 独自のmediaQuery用
import { ThemeProvider } from '@material-ui/styles';

// Context
import AppContext from '../fluxs/contexts/AppContext'; // AppContextによるApp全体でのstate利用
import reducer from '../fluxs/reducers'; // App全体でのstate更新処理を切り出し

// 画面部品
import Error from './Error';
import Start from './Start';
import Usage from './Usage';
import Catalog from './Catalog';
import Privacy from './Privacy';
import Terms from './Terms';

// デザイン
import { trixaTheme } from '../assets/styles/theme';
import '../assets/css/App.scss';


function App() {
  // App全体でのstateの初期値
  const initialState = {};

  // App全体でのstate利用準備
  const [state, dispatch] = useReducer(reducer, initialState);

  // stateが更新されるたびに呼ばれる処理
  useEffect(() => {
    console.log('App.js: re-rendering check.', state);
  }, [state]);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      <Router>
        <ThemeProvider theme={trixaTheme}>
          {/* メインコンテンツ */}
          <Switch>
            <Route exact path="/" component={Start} />
            <Route exact path="/help" component={Usage} />
            <Route exact path="/catalog" component={Catalog} />
            <Route exact path="/catalog/:productId" component={Catalog} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <Route component={Error} />
          </Switch>
          {/* メインコンテンツ */}
        </ThemeProvider>
      </Router>
    </AppContext.Provider>
  );
}

export default App;
