import {
  UPDATE_CATEGORIES,
} from '../actions';

const categories = (state = [], action) => {
  switch (action.type) {
    case UPDATE_CATEGORIES: {
      const { updatedCategories } = action;
      console.log('reducers: UPDATE_CATEGORIES', { updatedCategories });
      return updatedCategories;
    }
    default: {
      return state;
    }
  }
};

export default categories;
