// TODO: フラグメント化
const responseProduct = `
Category
CreatedTime
CreatorID
Introduction {
  lang
  introduction
}
LastUpdate
Price
ProductID
Published
PublishedChangeDate
RentalPeriod
Thumbnail
Title{
  lang
  title
  titleKana
}
`;
export const getCategories = `
  query list($input: ListCategoriesInput){
    listCategories(input: $input){
      items{
        CategoryID
        CategoryName
      }
      nextToken
    }
  }
`;
export const getProduct = `
  query getProduct($GetProductInput: GetProductInput!) {
    getProduct(input: $GetProductInput){
      ${responseProduct}
    }
  }
`;
export const getProductsByCreatorID = `
  query getProductsByCreatorID($GetProductsByCreatorIDInput: GetProductsByCreatorIDInput!) {
    getProductsByCreatorID(input: $GetProductsByCreatorIDInput){
      ${responseProduct}
    }
  }
`;
export const getProductDetail = `
  query getProductDetail($GetProductDetailInput: GetProductDetailInput!) {
    getProductDetail(input: $GetProductDetailInput){
      ProductID
      Questions{
        Answer
        Choices
        Explanation
        Question
        QuestionID
      }
    }
  }
`;
export const searchProducts = `
  query searchProducts($filter: ListProductsFilterInput){
    listProducts(filter: $filter, limit: ___LIMIT___){
      items{
        ${responseProduct}
      }
      nextToken
    }
  }
`;
export const getProductByProductId = `
  query get($GetProductByProductIdInput: GetProductInput!){
    getProduct(input: $GetProductByProductIdInput){
      ${responseProduct}
    }
  }
`;

// AlexaApp001ProductDetail用フラグメント
const fragmentProductDetail = `
  fragment f_ProductDetail on AlexaApp001ProductDetail {
    ProductID
    Questions{
      Answer
      Choices
      Question
    }
  }
`;

export const makeQueryGetSamples = (sampleNum) => {
  const sampleArr = new Array(sampleNum).fill(1);
  return `
    ${fragmentProductDetail}
    query getProductDetailSamples(
      ${sampleArr
    .map((id, idx) => `$pid${idx}: GetProductDetailInput!`)
    .join(',\n')}
    ) {
      ${sampleArr
    .map((id, idx) => `prd${idx}: getProductDetailSample(input: $pid${idx}){ ...f_ProductDetail }`)
    .join(',\n')}
    }
  `;
};
