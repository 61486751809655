import React, { useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons

// custom components
import CatalogHeader from './CatalogHeader';
import CatalogSearchArea from './CatalogSearchArea';
import CatalogSideMenu from './CatalogSideMenu';
import CatalogScrollMenu from './CatalogScrollMenu';
import CatalogMainContents from './CatalogMainContents';
import ProductDetailModal from './ProductDetailModal';
import Footer from '../Footer';

// fluxs
import CatalogContext from '../../fluxs/contexts/CatalogContext'; // CatalogContextによるApp全体でのstate利用
import reducer from '../../fluxs/reducers'; // Catalog全体でのstate更新処理を切り出し

// other utilities
import {
  isSp,
  getParamObj,
  getProductsHistory,
} from '../../utils';
import { categories, defaultCategory, categoyRealIds } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  mainContentWrapper: {
    color: 'white',
    backgroundColor: '#3b4043',
    [theme.breakpoints.down('sm')]: {},
  },
  mainContent: {
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8),
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 4, 4, 4),
    },
    [theme.breakpoints.up('md')]: {
      width: '100%',
      maxWidth: '100%',
      paddingLeft: 40,
      paddingRight: 40,
    },
    [theme.breakpoints.up('lg')]: {
      width: 1160,
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  line: {
    borderBottom: 'dashed 2px #555859',
  },
  sidemenuArea: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6, 4, 0, 0),
    },
  },
  itemsArea: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
      padding: theme.spacing(3, 0, 0, 0),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(5, 0, 0, 0),
    },
  },
}));

const Catalog = ({ match }) => {
  const classes = useStyles();
  const location = useLocation();
  const paramObj = getParamObj(location);
  const { productId } = match.params;

  // Catalog全体でのstateの初期値
  const initialState = {
    dispModes: {
      isShowingProductModal: !!productId,
      isSearching: true,
      isSp,
      isSearchingByCategory: categoyRealIds.reduce((pre, id) => ({ [id]: true, ...pre }), {}),
    },
    products: {
      allQuiz: [],
      latest: [],
      editorsChoice: [],
      popularContents: [],
      history: getProductsHistory(),
    },
    condition: {
      search: paramObj.search ? paramObj.search : '',
      category: (
        categories.filter((c) => (c.id === paramObj.category))[0]
        || defaultCategory
      ).id,
      price: paramObj.price ? paramObj.price : 0,
      sort: paramObj.sort ? paramObj.sort : '',
    },
    categories: [],
  };

  // Catalog全体でのstate利用準備
  const [state, dispatch] = useReducer(reducer, initialState);
  console.log('Catalog/index.js: re-rendering check.', { state });

  return (
    <CatalogContext.Provider value={{ state, dispatch }}>
      {/* header */}
      <CatalogHeader />

      {/* mainContent */}
      <Box className={classes.mainContentWrapper}>
        <Container maxWidth="md" className={classes.mainContent}>

          {/* searchbox */}
          <CatalogSearchArea />

          {/* line */}
          <Hidden smDown>
            <Box className={classes.line} />
          </Hidden>

          <Grid container>
            {/* sidemenu */}
            <Grid item md={3} className={classes.sidemenuArea}>
              <Hidden smDown>
                <CatalogSideMenu />
              </Hidden>
              <Hidden mdUp>
                <CatalogScrollMenu />
              </Hidden>
            </Grid>

            {/* itemsArea */}
            <Grid item sm={12} md={9} className={classes.itemsArea}>
              <CatalogMainContents />
            </Grid>
          </Grid>

          {/* footer */}
          <Footer />

        </Container>
      </Box>

      <ProductDetailModal productId={productId} />
    </CatalogContext.Provider>
  );
};

Catalog.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      productId: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default Catalog;
