import {
  CHANGE_CONDITION,
} from '../actions';

const useableConditions = ['search', 'category', 'price', 'sort'];


const products = (state = null, action) => {
  switch (action.type) {
    case CHANGE_CONDITION: {
      const updateConditions = {};
      // 設定された条件だけ更新対象に追加
      useableConditions.filter((x) => typeof action[x] !== 'undefined').forEach((x) => {
        updateConditions[x] = action[x];
      });
      console.log('reducers: CHANGE_CONDITION', { updateConditions });
      return { ...state, ...updateConditions };
    }
    default: {
      return state;
    }
  }
};

export default products;
