import React from 'react';
import Icon from '@mdi/react';
import {
  mdiClose,
  mdiHelp,
} from '@mdi/js';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
      help: null,
      index: 0,
      pos: null,
      prev: null,
      clickScroll: false,
    };
    this.handleswipe = this.handleswipe.bind(this);
    this.onScroll = this.onScroll.bind(this);
  }
  componentDidMount() {
    this.handleswipe(0, true);
  }
  handleswipe(dir, init) {
    dir = parseInt(dir);
    if (dir || init || dir == 0) {
      let index = Math.abs(dir);
      if (!init) {
        this.setState({
          index: index,
        });
      }
      let parent = document.getElementsByClassName('help-menu-list')[0];
      let slide = document.getElementsByClassName('help-anchor')[0];
      let li = slide.getElementsByClassName('help-anchor-li');
      let slideLength = 0;
      for (let i = 0; i < li.length; i++) {
        if (i <= Math.abs(index)) {
          if (i == Math.abs(index)) {
            slideLength += li[i].offsetWidth / 2;
          } else {
            slideLength += li[i].offsetWidth;
          }
        }
      }
      if (init || dir == 0) {
        if (window.scrollY < 200) {
          slideLength = li[0].offsetWidth / 2;
        }
      }
      parent.scrollLeft = slideLength;
    }
  }
  componentDidMount() {
    if (window.innerWidth < 640) {
      window.addEventListener('scroll', this.onScroll);
    }
  }
  onScroll() {
    if (!this.state.clickScroll) {
      if (!this.state.pos) {
        let parts = document.getElementsByClassName('help-parts');
        let pos = [];
        for (let i = 0; i < parts.length; i++) {
          pos.push({
            id: parts[i].id,
            pos: parts[i].offsetTop + 50,
          });
        }
        this.setState({
          pos: pos,
        });
      }
      const scrollY = window.scrollY - 70;
      let posY = this.state.pos;
      let target = null;
      posY.forEach((pos) => {
        if (pos.pos < scrollY) {
          target = pos;
        }
      });

      let ind = 0;
      if (target && this.state.prev != target.id) {
        ind = -parseInt(target.id.replace(/anchor/g, ''));
        this.setState({
          prev: target.id,
        });
        this.handleswipe(ind);
      } else if (!target && this.state.prev) {
        this.setState({
          prev: null,
        });
        this.handleswipe(0);
      }
    }
  }
  toggleMenu(e) {
    this.setState({
      menuOpen: !this.state.menuOpen,
    });
  }
  scrollTo(e, anchor) {
    e.preventDefault();
    e.stopPropagation();
    let ind = parseInt(e.target.id.replace('link-', ''));
    this.setState({
      index: ind,
    });
    let top = document.getElementById(anchor).offsetTop;
    document.documentElement.scrollTop = top - (window.innerWidth < 800 ? -70 : 220);
    this.setState({
      clickScroll: true,
    });
    this.handleswipe(ind);
    let st = this;
    setTimeout(() => {
      st.setState({
        clickScroll: false,
      });
    }, 1000);
    return false;
  }
  render() {
    let state = [];
    if (this.props.state && this.props.state.help) {
      state = this.props.state.help.datasources.guide;
    }
    if (window.innerWidth < 640) {
      setTimeout(() => {
        if (this.state.index == 0) {
          this.handleswipe(0, true);
        }
      }, 1000);
    }
    return (<
      div className = "help-menu-wrap" >
      <
        dl className = {
          'help-menu' + (this.state.menuOpen ? ' open' : '')
        } >
        <
          dt className = "header-page-title" > < img className = "header-logo"
            src = "/assets/img/svg/pagettl_help.svg"
            alt = "Trixa" / > < /dt> <
          dd className = "help-menu-list" >
          <
            h2 className = "help-menu-title"
            onClick = {
              (e) => this.toggleMenu(e)
            } >
            <
              Icon className = "help-menu-icon"
              path = {
                !this.state.menuOpen ? mdiHelp : mdiClose
              }
              title = "Menu"
              size = {
                1
              }
            /> <
              span className = "help-menu-title-text" > Help < /span> <
          /h2> <
            ul className = "help-anchor" > {
              state.map((item, index) => (<
                li key = {
                  item.anchor
                }
                className = "help-anchor-li" >
                <
                  a href = {
                    item.anchor
                  }
                  className = {
                    'help-anchor-link ' + (Math.abs(this.state.index) == index ? 'active' : '')
                  }
                  id = {
                    'link-' + index
                  }
                  onClick = {
                    (e) => this.scrollTo(e, item.anchor)
                  } >
                  <
                    span className = "help-anchor-link-text" > {
                      item.title
                    } < /span> <
                /a> <
              /li>
              ))
            } <
          /ul> <
        /dd> <
      /dl> <
    /div>
    );
  }
};

export default Menu;