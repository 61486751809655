import React from 'react';
import Header from '../Header';
import Menu from './Menu';
import Contents from './Contents';
import Footer from '../Footer';

class Usage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      help: null,
    };
  }

  componentDidMount() {
    this.getHelpContents();
  }

  getHelpContents() {
    fetch('/assets/json/alexa-guide.json', {})
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error(res.status);
        }
      })
      .then(
        (result) => {
          this.setState({
            help: result,
          });
        },
        (error) => {
          this.setState({
            help: null,
          });
        },
      );
  }
  render() {
    return (
      <div>
        <Header active="help"/>
        <Menu state={this.state} />
        <div className="help-inner">
        	<Contents state={this.state} />
        </div>
        <Footer />
      </div>
  	);
  }
}

export default Usage;
