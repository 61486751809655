import React from 'react';
import PropTypes from 'prop-types';

// material-ui styles
import { makeStyles } from '@material-ui/core/styles';

// material-ui components
import Box from '@material-ui/core/Box';

// material-ui icons

// custom components
import NewBadge from './NewBadge';

// fluxs

// other utilities

const useStyles = makeStyles((theme) => ({
  coverHover: {
    position: 'relative',
    zIndex: 10,
    width: '100%',
    height: '100%',
  },
  coverContents: {
    width: '100%',
    position: 'relative',
    '&::before': {
      display: 'block',
      content: '\'\'',
      paddingTop: '100%',
    },
  },
  coverImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    [theme.breakpoints.down('sm')]: {
      width: 120,
      height: 120,
      borderRadius: theme.spacing(2),
      boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)',
    },
    [theme.breakpoints.up('md')]: {
      width: 230,
      height: 230,
      borderRadius: theme.spacing(2),
      boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.2)',
    },
  },
}));

const Thumbnail = ({ url, badgePosition }) => {
  const classes = useStyles();
  return (
    <Box className={classes.coverHover}>
      <NewBadge badgePosition={badgePosition} />
      <div className={classes.coverImage} style={{ backgroundImage: `url(${url})` }} />
    </Box>
  );
};

Thumbnail.defaultProps = {
  badgePosition: 'leftTop',
};

Thumbnail.propTypes = {
  url: PropTypes.string.isRequired,
  badgePosition: PropTypes.string,
};

export default Thumbnail;
